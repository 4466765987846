<template>
    <div class="toggle-switch">
        <div class="toggle-switch.container">
            <label class="toggle-switch.switch">
                <input type="checkbox" class="toggle-switch.switch.checkbox" v-model="toggle" @input="handleToggle"/>
                <span class="toggle-switch.switch.slider"></span>
            </label>
            <span class="toggle-switch.label">{{ toggleLabel }}</span>
        </div>
    </div>
  </template>

<script>
export default {
    name: 'ToggleSwitch',

    emits: [
      'toggle'
    ],

    props: {
        value: {
            type: Boolean,
            required: true
        },
        selectedMessage: {
            type: String,
            required: true
        },
        unselectedMessage: {
            type: String,
            required: true
        }
    },

    computed: {
        toggle: {
            get() { return this.value }
        },
        toggleLabel() {
            return this.toggle ? this.selectedMessage : this.unselectedMessage;
        }
    },

    methods: {
        toggleSwitch() {
            this.toggle = !this.toggle
        },

        handleToggle() {
            this.toggle = !this.toggle;
            this.$emit('toggle', this.toggle);
        },
        setToggle(toggle) {
            this.toggle = toggle;
        }
    },

    
}
</script>