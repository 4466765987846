<template>
  <div>
    <div v-if="showDowntimeNotification" class="system-downtime">
      <div class="system-downtime.popover">
        <span class="system-downtime.popover.close" @click="closeDowntimeNotification"></span>
        
          <div class="system-downtime.popover.content" v-for="downtime in downtimes" :key="downtime.service">
            <div v-if="
              downtime.service === 'ND_DELAYED' || 
              downtime.service === 'SD_DELAYED' ||
              downtime.service === 'ND_UNAVAILABLE' ||
              downtime.service === 'ND_UNAVAILABLE'
            ">
              <span class="system-downtime.popover.content.title">{{ downtime.writtenService }}</span>
              <span class="system-downtime.popover.content.reason">{{ downtime.reason }}</span>
            </div>

            <div v-else>
              <span class="system-downtime.popover.content.title">Sorry, we are currently unable to provide services from {{ downtime.writtenService }} due to:</span>
              <span class="system-downtime.popover.content.reason">{{ downtime.reason }}</span>
              <span class="system-downtime.popover.content.info">
                {{ downtime.writtenService }} are currently expected to be available from:
              </span>
              <span class="system-downtime.popover.content.time">{{ formatTime(downtime.utsEnd) }}</span>
              <span class="system-downtime.popover.content.date">on {{ formatDate(downtime.utsEnd) }}</span>
            </div>
            
          </div>
          <span class="system-downtime.popover.content.info">We apologise for any inconvenience this may cause.</span>
          <button class="main-button" @click="closeDowntimeNotification">
            <span class="main-button.text">I understand</span>
          </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemDowntime',

  data() {
    return {
      downtimes: [],
      showDowntimeNotification: false
    }
  },

  methods: {
    closeDowntimeNotification() {
      this.showDowntimeNotification = false
      clearInterval(this.intervalId)
    },

    async checkSystemDowntime() {
      const apiResponse = await fetch(process.env.VUE_APP_API_SYSTEMDOWNTIME, { headers: { 'Authorization': process.env.VUE_APP_AUTHORIZATION } })
      const responseJson = await apiResponse.json()

      const systemDowntimes = responseJson.systemDowntimes
      const currentDate = Math.round(Date.now() / 1000)

      this.downtimes = systemDowntimes.filter(downtime => downtime.utsStart && downtime.utsEnd && downtime.utsStart <= currentDate && downtime.utsEnd >= currentDate)
      this.showDowntimeNotification = this.downtimes.length > 0

      if (!this.showDowntimeNotification) {
        clearInterval(this.intervalId)
      }
    },

    formatTime(time) {
      return new Date(time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },

    formatDate(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }
      return new Date(date * 1000).toLocaleDateString("en-GB", options)
    }
  },

  mounted() {
    this.checkSystemDowntime()
    this.intervalId = setInterval(this.checkSystemDowntime, 1000 * 5 * 60)
  }
}
</script>