<template>
    <div class="container">
      <fieldset class="recipient-details">
        <h2 class="recipient-details.title">Destination address</h2>
        <TypeaheadAddress 
          v-if="['IMN', 'GBR', 'GGY', 'JEY'].includes(mainstore.selected.destination.alpha3Code)" 
          :valid="addressValid" 
          @changed="updateAddress" 
          :region="this.mainstore.selected.destination.alpha3Code" />
        <AddressForm 
          @valid="validateAddress" 
          @invalid="invalidateAddress"
          @changed="validate" 
          v-if="addressPayload.length !== 0"
          :payload="addressPayload"
          :addressValid="addressValid"
        />
        <LoaderIcon v-else></LoaderIcon>
        <label for="recipientCountry" class="form-label">Country</label>
        <div class="form-field-icon --disabled">
          <input disabled type="text" class="form-text-field --disabled" name="recipientCountry" v-model="mainstore.selected.destination.name"/>
        </div>
      </fieldset>
      <fieldset class="recipient-details">
        <h2 class="recipient-details.title">Recipient details</h2>
        <div class="recipient-details.business-container">
          <h3 class="recipient-details.sub-title">Are you sending to a business?</h3>
          <ToggleSwitch
            :value="isBusiness"
            selectedMessage="I am sending to a business"
            unselectedMessage="I am not sending to a business"
            @toggle="setIsBuisness"></ToggleSwitch>
        </div>
        <br/>
        <div v-if="isBusiness">
          <label for="recipientFirstName" class="form-label">Business name<span class="form-label.required">*</span></label>
          <div class="form-text-field.container" :class="{ '--valid': businessNameValid === true}">
            <input 
              type="text" 
              class="form-text-field" 
              name="recipientFirstName" 
              v-model.trim="mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY" 
              @blur="validateBusinessName(true)"
            />
            <p class="error" v-if="businessNameTouched && businessNameValid === false">You must enter a business name</p>
          </div>
        </div>
        <label for="recipientFirstName" class="form-label">First name<span class="form-label.required" v-if="!isBusiness">*</span></label>
        <div class="form-text-field.container" :class="{ '--valid': firstNameValid === true}">
          <input 
            type="text" 
            class="form-text-field" 
            name="recipientFirstName" 
            v-model.trim="mainstore.selected.recipientDetails.firstName" 
            @blur="validateFirstName(true)"
          />
        </div>
        <p class="error" v-if="firstNameTouched && firstNameValid === false">You must enter a first name greater than three characters in length</p>
        <label for="recipientLastName" class="form-label">Last name<span class="form-label.required" v-if="!isBusiness">*</span></label>
        <div class="form-text-field.container" :class="{ '--valid': lastNameValid === true}">
          <input 
            type="text" 
            class="form-text-field" 
            name="recipientLastName" 
            v-model.trim="mainstore.selected.recipientDetails.lastName" 
            @blur="validateLastName(true)"
          />
        </div>
        <p class="error" v-if="lastNameTouched && lastNameValid === false">You must enter a last name greater than two characters in length</p>
        <p class="error" v-if="firstNameTouched && lastNameTouched && fullNameValid === false">Both the first and last name must be less than 40 characters combined</p>
        <div v-if="mainstore.selected.serviceDetails.controlCode !== 'IM'">
            <label for="recipientContactTelephone" class="form-label">Recipient contact telephone<span class="form-label.required">*</span></label>
            <div class="form-text-field.container" :class="{ '--valid': telephoneValid === true}">
            <input 
                type="tel" 
                class="form-text-field"
                name="recipientContactTelephone" 
                v-model.trim="mainstore.selected.recipientDetails.telephone" 
                @blur="validateTelephone"
            />
            </div>
            <p class="error" v-if="telephoneTouched && telephoneValid === false">You must enter a valid telephone number</p>
        </div>
      </fieldset>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useMainStore } from '@/stores/MainStore.ts';
  import AddressForm from '@/components/form-components/AddressForm.vue';
  import LoaderIcon from '@/components/form-components/LoaderIcon.vue';
  import ToggleSwitch from './form-components/ToggleSwitch.vue';
  import TypeaheadAddress from '@/components/TypeaheadAddress.vue';
  
  export default {
    name: 'RecipientDetails',
    components: {
      AddressForm,
      LoaderIcon,
      ToggleSwitch,
      TypeaheadAddress
    },
    setup() {
      const mainstore = useMainStore();
      return {
        mainstore
      };
    },
    data() {
      return {
        isBusiness: false,
        addressPayload: [],
        businessNameValid: null,
        businessNameTouched: false,
        firstNameValid: null,
        firstNameTouched: false,
        lastNameValid: null,
        lastNameTouched: false,
        fullNameValid: false,
        telephoneValid: null,
        telephoneTouched: false,
        addressValid: null,
      };
    },
    created() {
      this.fetchAddressPayload();
      if(this.mainstore.selected.serviceDetails.controlCode === 'IM') {
        this.telephoneValid = true;
      }
    },
    mounted() {
      if(this.mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1) {
        this.changeBusinessType();
        this.validateRecipientDetails();

        if(this.mainstore.selected.serviceDetails.controlCode !== 'IM') {
          this.validateTelephone();
        }
      }
      const toggleSwitch = this.$refs.ToggleSwitch;
      if (toggleSwitch) {
        toggleSwitch.setToggle(this.isBusiness);
      }
    },

    methods: {
      fetchAddressPayload() {
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_CUSTOMSFORMAT,
          params: {
            product: this.mainstore.selected.serviceDetails.id,
            country: this.mainstore.selected.destination.alpha3Code,
            itemformat: this.mainstore.selected.parcelSize.itemFormat
          },
          headers: {
            'Authorization': process.env.VUE_APP_AUTHORIZATION,
          }
        })
        .then(response => {
          this.addressPayload = response.data.recipientLines;
          delete this.addressPayload.RECIPIENTCOMPANY;
        })
        .catch(err => {
          console.error('Error fetching address payload:', err);
        });
      },
      validateBusinessName(manualInput) {
        let data = this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY;
        console.log(data);
        if (this.isBusiness && data) {
          this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY = data;
          this.businessNameValid = data != '' && data.length >= 1;
        } else {
          this.businessNameValid = false;
        }
        if (data || manualInput) {
          this.businessNameTouched = true;
        }
        this.validate();
      },
      validateFirstName(manualInput) {
        let data = this.mainstore.selected.recipientDetails.firstName;
        if (this.isBusiness) {
          this.firstNameValid = data === '' || data.length >= 3 || data;
        } else {
          this.firstNameValid = data === '' || data.length >= 3 || data;
          this.firstNameValid = data !== '' && data.length >= 3;
        }
        if (data || manualInput) {
          this.firstNameTouched = true;
        }
        this.fullNameValid = data.length + this.mainstore.selected.recipientDetails.lastName.length < 40;
        this.validate();
      },
      validateLastName(manualInput) {
        let data = this.mainstore.selected.recipientDetails.lastName;
        if (this.isBusiness) {
          this.lastNameValid = data === '' || data.length >= 3  || data;
        } else {
          this.lastNameValid = data !== '' && data.length >= 2;
        }
        if (data || manualInput) {
          this.lastNameTouched = true;
        }
        this.fullNameValid = data.length + this.mainstore.selected.recipientDetails.firstName.length < 40;
        this.validate();
      },
      validateRecipientDetails() {
        if (this.isBusiness) {
          this.businessNameTouched = false;
          this.validateBusinessName();
          this.fullNameValid = true;
        } else {
          this.fullNameValid = false;
          this.firstNameTouched = false;
          this.validateFirstName(false);
          this.lastNameTouched = false;
          this.validateLastName(false);
        }
        this.validate();
      },
      validateTelephone() {
        let data = this.mainstore.selected.recipientDetails.telephone;
        // eslint-disable-next-line
        this.telephoneValid = /^[\(\)\+ 0-9]{10,20}$/.test(data);
        this.telephoneTouched = true;
        this.validate();
      },
      setIsBuisness() {
        this.isBusiness = !this.isBusiness;

        var firstName = this.mainstore.selected.recipientDetails.firstName;
        var lastName = this.mainstore.selected.recipientDetails.lastName;

        this.businessNameTouched = this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY ? true : false;
        this.businessNameValid = this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY ? true : false;
        // this.firstNameTouched = firstName || firstName == '' ? true : false;
        this.firstNameValid = firstName || firstName == '' ? true : false;
        // this.lastNameTouched = lastName || lastName == '' ? true : false;
        this.lastNameValid = lastName || lastName == '' ? true : false;

        this.validateRecipient();
        this.validateRecipientDetails();
      },
      changeBusinessType() {
        console.log(this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY);
        if (this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY) {
          this.isBusiness = true;
          this.businessNameTouched = true;
        } else {
          this.businessNameTouched = false;
          this.isBusiness = false;
        }

        this.validateRecipient();
        },
      validateRecipient() {
        if (this.businessNameTouched) {
          this.validateBusinessName(false);
        } 
        if (this.firstNameTouched) {
          this.validateFirstName(false);
        }
        if (this.lastNameTouched) {
          this.validateLastName(false);
        }
      },
      validateAddress() {
        this.validate();
        if (this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY) {
          this.isBusiness = true;
        } else {
          this.isBusiness = false;
        }
        this.addressValid = true;
        this.validateRecipientDetails();
      },
      invalidateAddress() {
        this.addressValid = false;
        this.validate();
      },
      addressChanged() {
        this.changeBusinessType();
        this.validateRecipientDetails();
        // this.validate(); Handled in validatedRecipientDetails()
      },
      validate() {
        const firstName = this.mainstore.selected.recipientDetails.firstName;
        const lastName = this.mainstore.selected.recipientDetails.lastName;

        if (!this.isBusiness) {
          this.mainstore.validations.recipientDetailsValid =
            this.firstNameValid &&
            this.lastNameValid &&
            this.fullNameValid &&
            this.telephoneValid &&
            this.addressValid;
        } else if (firstName || lastName) {
          this.mainstore.validations.recipientDetailsValid =
            this.businessNameValid &&
            this.firstNameValid &&
            this.lastNameValid &&
            this.fullNameValid &&
            this.telephoneValid &&
            this.addressValid;
        } else {
          this.mainstore.validations.recipientDetailsValid =
            this.businessNameValid &&
            this.telephoneValid &&
            this.addressValid;
        }
      },
      updateAddress(address) {
        this.mainstore.selected.recipientDetails.address.RECIPIENTCOMPANY = undefined;
        this.addressValid = true;
        this.validate();
        console.log(address.address.ORG);
        this.mainstore.$patch((state) => {
          state.selected.recipientDetails.address.RECIPIENTCOMPANY = address.address.ORG || ""
          state.selected.recipientDetails.address.RECIPIENTADDRESSLINE1 = address.address.ADDRESSLINE1 || ""
          state.selected.recipientDetails.address.RECIPIENTADDRESSLINE2 = address.address.ADDRESSLINE2 || ""
          state.selected.recipientDetails.address.RECIPIENTADDRESSLINE3 = address.address.ADDRESSLINE3 || ""
          state.selected.recipientDetails.address.RECIPIENTCITY = address.address.CITY
          state.selected.recipientDetails.address.RECIPIENTCOUNTY = address.address.COUNTY || ""
          state.selected.recipientDetails.address.RECIPIENTPOSTCODE = address.address.POSTCODE
          state.hasChanged = true
        })
        this.validateAddress();
        this.addressChanged();
      }
    },
  };
  </script>
  
  