<template>
  <div>
    <div v-if="showCookiePrompt" class="cookie-prompt">
      <div class="cookie-prompt.popover">
        <span class="cookie-prompt.popover.content.title">
          We use cookies
        </span>
        <span class="cookie-prompt.popover.content.info">
          These help us to improve our site and enable us to deliver the best possible service and customer experience.
          By clicking accept you are agreeing to our
          <router-link to="/cookiepolicy" ><span class="main-button.text">cookie policy.</span></router-link>
        </span>
        <button class="main-button" style="margin-bottom: 10px;" @click="acceptCookies">
          <span class="main-button.text">Accept</span>
        </button>
        <button class="secondary-button" @click="declineCookies">
          <span class="main-button.text">Decline</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const COOKIE_CONSENT_VERSION = "cookie_consent_v2"

window.dataLayer = window.dataLayer || []

export default {
  name: 'CookiePrompt',

  data() {
    return {
      showCookiePrompt: true
    };
  },

  methods: {
    acceptCookies() {
      this.injectGoogleAnalytics()
      this.injectFacebookPixel()

      window.localStorage.setItem(COOKIE_CONSENT_VERSION, "yes")
      this.showCookiePrompt = false;
    },

    declineCookies() {
      window.localStorage.setItem(COOKIE_CONSENT_VERSION, "no")
      this.showCookiePrompt = false
    },

    injectGoogleAnalytics() {
      let gaScript = document.createElement('script')
      gaScript.src = "https://www.googletagmanager.com/gtag/js?id=" + process.env.VUE_APP_GOOGLE_TAGMANAGER;
      gaScript.async = true
      
      gaScript.onload = function() {
        function gtm() { window.dataLayer.push(arguments) }
        gtm('js', new Date());
        gtm('config', process.env.VUE_APP_ANALYTICS_KEY)
      }

      const s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(gaScript, s)
    },

    injectFacebookPixel() {
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');

      window.fbq('init', process.env.VUE_APP_FACEBOOK)
      window.fbq('track', 'PageView')
    }
  },

  mounted() {
    const consent = window.localStorage.getItem(COOKIE_CONSENT_VERSION)
    if (consent && consent == "yes")
      this.acceptCookies() // inject scripts and close the prompt
    else if (consent && consent == "no")
      this.showCookiePrompt = false; // close prompt
    else 
      this.showCookiePrompt = true; // show consent prompt
  }
};
</script>