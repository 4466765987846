<template>
    <div v-if="message" class="container rich-text-editor --strapi">
        <StrapiBlocks :content="message" />
    </div>
</template>

<script>
    import { StrapiBlocks } from 'vue-strapi-blocks-renderer';

    export default{
        name: 'MarketingMessage',
        data(){
            return{
                message: null
            }
        },

        components: {
            StrapiBlocks
        },

        methods: {
            GetMessage(){
                fetch(process.env.VUE_APP_API_MARKETING_MESSAGE, { headers: { 'Authorization': process.env.VUE_APP_AUTHORIZATION } })
                .then(resp => {
                    return resp.json()
                })
                .then(json => {
                    this.message = json.data.Message
                })
                .catch(error => {
                    console.log('Error fetching marketing message: ' + error)
                })
            }
        },

        mounted(){
            this.GetMessage()
        }
    }
</script>